.order-articles {
  display: flex;
  align-items: center;
  flex-direction: column;
  color: $brandPrimary;

  .order-article-row {
    display: grid;
    grid-template-columns: 3fr 1fr 2fr;
    width: 100%;
    align-items: center;
    padding: 1rem;
    font-weight: $bold;
    border: 1px solid $brandPrimary;
    border-top: none;

    &:first-of-type {
      border-top: 1px solid $brandPrimary;
    }

    &__name {
      font-size: 1.1rem;
    }

    &__installation-information {
      text-align: center;
    }
  }
}

@media screen and (min-width: $tablet) {
  .order-articles {
    .order-article-row {
      &__name {
        font-size: 1.3rem;
      }
    }
  }
}

.MuiFormControl-root {
  .MuiInputLabel-root {
    font-size: 1.2rem;
  }
  .MuiInputBase-root {
    .MuiInputBase-input {
      font-size: 1.2rem;
    }

    fieldset {
      font-size: 1.2rem;
    }
  }
}

@media screen and (min-width: $tablet) {
  .MuiFormControl-root {
    .MuiInputLabel-root {
      font-size: 1.6rem;
    }
    .MuiInputBase-root {
      .MuiInputBase-input {
        font-size: 1.6rem;
      }

      fieldset {
        font-size: 1.6rem;
      }
    }
  }
}

.appointment {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $brandPrimary;

  &__loading {
    justify-self: center;
  }

  .MuiCalendarPicker-root {
    border: 2px solid $brandPrimary;
    margin: 0;
    border-radius: 1rem;

    .MuiPickersArrowSwitcher-root {
      .MuiIconButton-root {
        color: $brandPrimary;
      }
    }

    .PrivatePickersSlideTransition-root {
      //min-height: initial;
    }
  }
  .MuiDayPicker-monthContainer {
    .MuiPickersDay-root {
      font-size: 1rem;
    }
    .MuiPickersDay-root:not(.Mui-disabled) {
      background-color: $brandPrimary;
      color: white;
    }
    .MuiPickersDay-root:is(.Mui-selected) {
      background-color: $brandSecondary;
      color: $brandPrimary;
    }
  }

  .time-slot-selector {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__title {
      font-size: 1.2rem;
      margin-bottom: 0.8rem;
      text-align: center;
    }

    &__time-slots {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      width: 100%;
      margin-bottom: 1rem;

      .time-slot-item {
        cursor: pointer;
        border: 2px solid $brandPrimary;
        padding: 0.5rem 1rem;
        border-radius: 0.5rem;

        &:hover,
        &:focus,
        &--selected {
          background-color: $brandSecondary;
          color: $brandPrimary;
          border: $brandSecondary;
        }
      }
    }

    &__no-suitable-slots {
      margin-top: 1rem;
      .MuiTypography-root {
        font-size: 1rem;
      }
    }

    &__submit {
      margin-top: 0rem;
    }

    &__error {
      color: #ff1744;
      margin: 1rem 0;
      text-align: center;
    }
  }
}

@media screen and (min-width: $desktop) {
  .appointment {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: flex-start;

    &__calendar-container {
      display: flex;
      justify-content: center;
    }

    .time-slot-selector {

      &__no-suitable-slots {

        .MuiTypography-root {
          font-size: 1.2rem;
        }
      }
    }
  }
}

@import "./TimeSlotSelector/TimeSlotSelector";

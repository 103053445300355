.delivery {
}

.cancelled {
  text-align: center;
  color: $brandPrimary;
  font-size: 2rem;
  margin: 2rem 0;
}
@media screen and (min-width: $tablet) {
  .cancelled {
    margin-bottom: 6rem;
  }
}

.timeline-view {
  display: flex;
  flex-direction: column;
  font-size: 1.1rem;

  .inlineBold {
    font-weight: $bold;
  }

  &__delivery {
    color: $brandPrimary;
    text-align: center;
    margin-bottom: 4rem;

    span {
      &:last-child {
        font-weight: $bold;
      }
    }
  }

  .MuiStepLabel-label {
    font-size: 1.1rem;
  }

  .MuiStepLabel-label:is(.Mui-active) {
    // font-weight: $bold;
  }
  &__footer {
    margin-top: 2rem;
    color: $brandPrimary;
    display: flex;
    align-items: center;
    flex-direction: column;

    flex-wrap: wrap;
    align-content: center;
    justify-content: center;

    :nth-child(-n + 2) {
      margin-bottom: 1rem;
    }
    :nth-child(n + 3) {
      margin-bottom: 0.3rem;
    }
    div {
      text-align: center;
    }

    a {
      text-decoration: none;
      color: $brandPrimary;
      font-weight: $bold;
    }
  }
  &__footer-two {
    margin-top: 1rem;
    margin-bottom: 1rem;
    color: $brandPrimary;
    font-size: 1rem;
    text-align: center;
  }

  &__review-link {
    display: flex;
    justify-content: center;
    margin-top: 2rem;

    a {
      cursor: pointer;
      font-weight: $regular;
      padding: 1rem 2rem;
      color: $white;
      border-radius: 0.4rem;
      background-color: lighten($brandPrimary, 5%);
      transition: background-color 0.2s;

      &:hover {
        background-color: $brandPrimary;
      }
    }
  }
}

.timeline-view__step.Mui-completed {
  svg {
    color: $brandSecondary;
  }
}

@media screen and (min-width: $tablet) {
  .timeline-view {
    font-size: 1.5rem;

    &__delivery {
      margin-bottom: 6rem;
    }

    &__footer {
      margin-top: 6rem;
    }

    &__review-link {
      margin-top: 4rem;
    }

    .timeline-view__stepper.MuiStepper-root {
      padding-left: 0;
    }
  }
}
@media screen and (max-width: $desktop) {
  .timeline-view {
    &__footer {
      border: 2px solid $brandPrimary;
      border-radius: 1rem;
      padding: 1rem;
    }
  }
  .timeline-view__stepper.MuiStepper-root {
    margin-top: 1rem;
    border: 2px solid $brandPrimary;
    border-radius: 1rem;
    padding-top: 2rem;
    padding-bottom: 1.5rem;
  }
}

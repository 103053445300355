.schedule-delivery-tabs {
  display: flex;
  flex-direction: column;
  width: 100%;

  .MuiTabs-root {
    margin-bottom: 1.5rem;

    .MuiTabs-flexContainer {
      justify-content: space-around;

      .MuiButtonBase-root {
        text-transform: none;
        font-weight: $bold;
      }
    }

    .MuiTabs-indicator {
      height: 4px;
    }
  }
}

@media screen and (min-width: $tablet) {
  .schedule-delivery-tabs {
    max-width: 60rem;

    .MuiTabs-root {
      margin-bottom: 3rem;

      .MuiTabs-flexContainer {
        justify-content: space-between;

        .MuiButtonBase-root {
          font-size: 1.5rem;
        }
      }
    }
  }
}

@import "./Fonts/fonts";

$fontFamily: "Roboto", "Montserrat", "Open Sans", "Helvetica Neue", Arial,
  sans-serif;

$light: 300;
$regular: 400;
$semiBold: 500;
$bold: 700;

$smallSize: 0.8rem;
$regularSize: 1rem;
$mediumSize: 1.4rem;
$largeSize: 2rem;
$veryLargeSize: 2.6rem;
$extraLargeSize: 2.8rem;

$defaultLineHeight: 1;
$mediumLineHeight: 1.3;

html {
  font: normal 16px $fontFamily;
}

body {
  color: $black;
  font-family: $fontFamily;
  font-size: $regularSize;
  font-style: normal;
  font-weight: $regular;
  line-height: $defaultLineHeight;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins";
  font-size: $regularSize;
  font-weight: $regular;
  line-height: $defaultLineHeight;
}

h1 {
  font-size: $extraLargeSize;
  font-weight: $bold;
  line-height: $mediumLineHeight;
}
h2 {
  font-size: $largeSize;
  font-weight: $semiBold;
  line-height: $mediumLineHeight;
}
h3 {
  font-size: $mediumSize;
}

.logo {
  width: 6rem;
}
.company-logo {
  width: 8rem;
}

.divider {
  width: 100%;
  height: 1px;
  background: $brandPrimary;
  margin: 0.7rem 0;
}

.footer__logo {
  width: 7rem;
  align-self: center;
  margin: 2rem 0;
}

@media screen and (min-width: $tablet) {
  .logo {
    width: 10rem;
  }
  .company-logo {
    width: 16rem;
  }
}

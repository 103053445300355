.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem 0;

  &__order-title {
    color: $brandPrimary;
    font-size: 1.1rem;
    margin-top: 1rem;
  }

  &__order-id,
  &__order-ref,
  &__confirmed-date {
    text-align: center;

    div {
      &:first-child {
        margin: 0.5rem 0;
        font-weight: $bold;
      }

      &:last-child {
        margin-bottom: 1rem;
      }
    }
    &__message {
      margin-top: 1rem;
      font-size: 1.5rem;
      color: white;
      background-color: green;
      border-radius: .5rem;
      padding: 5px;
    }
    &__sub-message {
      margin-top: 1rem;
      font-size: .9rem;
    }
  }
}

@media screen and (min-width: $tablet) {
  .header {
    padding-top: 3rem;
    padding-bottom: 6rem;

    &__order-title {
      font-size: 1.6rem;
    }
    &__confirmed-date {
     
      &__sub-message {
        font-size: 1.1rem;
      }
    }
  }
}

@media screen and (max-width: $desktop) {
  .header {
    &__order-title {
      border: 2px solid $brandPrimary;
      border-radius: 1rem;
      padding: 0.3rem 2rem;
    }
  }
}



.client-information-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  &__fields {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
  }

  &__action {
    padding-top: 1.5rem;
  }
}

@media screen and (min-width: $tablet) {
  .client-information-form {
    &__fields {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 2rem;
    }
  }
}

@media screen and (min-width: $desktop) {
  .client-information-form {
    &__fields {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 2rem;
    }
  }
}

.time-slot-selector {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem 1rem 0 1rem;

  &__title {
    font-size: 1.1rem;
    margin-bottom: 1rem;
    text-align: center;
  }

  &__time-slots {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
    margin-bottom: 1rem;

    .time-slot-item {
      cursor: pointer;
      border: 1px solid $brandPrimary;
      padding: 0.5rem 1rem;
      border-radius: 0.5rem;

      &:hover,
      &:focus,
      &--selected {
        background-color: $brandPrimary;
        color: white;
      }
    }
  }

  &__no-suitable-slots {
    margin-bottom: 1rem;

    .MuiTypography-root {
      font-size: 0.8rem;
    }
  }

  &__submit {
    margin-top: 2rem;
  }

  &__error {
    color: #ff1744;
    margin: 1rem 0;
    text-align: center;
  }
}

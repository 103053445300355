/* roboto-italic - latin */
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 400;
  src: url("./Roboto/roboto-v27-latin-italic.eot");
  src: local(""),
    url("./Roboto/roboto-v27-latin-italic.eot?#iefix")
      format("embedded-opentype"),
    url("./Roboto/roboto-v27-latin-italic.woff2") format("woff2"),
    url("./Roboto/roboto-v27-latin-italic.woff") format("woff"),
    url("./Roboto/roboto-v27-latin-italic.ttf") format("truetype"),
    url("./Roboto/roboto-v27-latin-italic.svg#Roboto") format("svg");
}
/* roboto-regular - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url("./Roboto/roboto-v27-latin-regular.eot");
  src: local(""),
    url("./Roboto/roboto-v27-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    url("./Roboto/roboto-v27-latin-regular.woff2") format("woff2"),
    url("./Roboto/roboto-v27-latin-regular.woff") format("woff"),
    url("./Roboto/roboto-v27-latin-regular.ttf") format("truetype"),
    url("./Roboto/roboto-v27-latin-regular.svg#Roboto") format("svg");
}
/* roboto-700italic - latin */
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 700;
  src: url("./Roboto/roboto-v27-latin-700italic.eot");
  src: local(""),
    url("./Roboto/roboto-v27-latin-700italic.eot?#iefix")
      format("embedded-opentype"),
    url("./Roboto/roboto-v27-latin-700italic.woff2") format("woff2"),
    url("./Roboto/roboto-v27-latin-700italic.woff") format("woff"),
    url("./Roboto/roboto-v27-latin-700italic.ttf") format("truetype"),
    url("./Roboto/roboto-v27-latin-700italic.svg#Roboto") format("svg");
}
/* roboto-700 - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: url("./Roboto/roboto-v27-latin-700.eot");
  src: local(""),
    url("./Roboto/roboto-v27-latin-700.eot?#iefix") format("embedded-opentype"),
    url("./Roboto/roboto-v27-latin-700.woff2") format("woff2"),
    url("./Roboto/roboto-v27-latin-700.woff") format("woff"),
    url("./Roboto/roboto-v27-latin-700.ttf") format("truetype"),
    url("./Roboto/roboto-v27-latin-700.svg#Roboto") format("svg");
}

.schedule-delivery {
  display: flex;
  justify-content: center;

  .tab-navigation {
    margin: 2rem 0;

    &_container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .Mui-disabled {
      // display: none;
    }

    .MuiSvgIcon-root {
      margin-bottom: 1px;
    }
  }
}
